<template>
  <div>
    <dashboard-page-title @addEvent="$router.push({name: 'addMerchant'})">المتاجر</dashboard-page-title>

    <main-table :baseURL="VUE_APP_MARKETPLACE_LINK" :fields="fields" list_url="admin/stores"  :additional-url="`&status=completed`"></main-table>
  </div>
</template>
<script>
/* eslint-disable */ 
import { core } from '@/config/pluginInit'
import { VUE_APP_MARKETPLACE_LINK } from '@/config/constant'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      VUE_APP_MARKETPLACE_LINK,
      fields: [
        { label: 'صورة الشعار', key: 'logo', type: 'image', class: 'text-right' },
        { label: 'صورة الغلاف', key: 'cover', type: 'image', class: 'text-right' },
        { label: 'الاسم', key: 'name', class: 'text-right' },
        { label: 'الحالة', key: 'status', type: 'status', class: 'text-right' },
        { label: 'التاريخ', key: 'created_at', class: 'text-right' },
        {
          label: 'التحكم',
          key: 'actions',
          class: 'text-right',
          type: 'actions',
          actions: [
            {
              text: 'Delete',
              icon: 'ri-delete-bin-line',
              color: 'danger',
              url: 'admin/stores',
              baseUrl: VUE_APP_MARKETPLACE_LINK,  
              titleHeader: 'متجر',
              question: 'هل متأكد أنك تريد مسح هذا المتجر',
              textContent: 'name',
              textDeleteButton: 'بالتأكيد، مسح',
              textCancelButton: 'إلغاء',
              showAlert: true
            }
          ]
        }
      ]
    }
  },
  methods: {
    addMerchant () {
      console.log('hello')
    }
  }
}
</script>
